import React, { useState, useCallback } from 'react';
import styles from './AppointmentList.module.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AppointmentTabs from './../AppointmentTabs/AppointmentTabs';
import refresh from './../../../styles/Images/appointementListDesktop/refresh.png';
import AppointmentListContent from './../AppointmentListContent/AppointmentListContent';
import trackEvent from './../../../utils/trackEvent';
import { getPageName } from './../../../utils/Utils';

const AppointmentList = ({ handleClickOpen, setCurrentAppointment, pastAppointmentList, nextAppointmentList, refreshAppointmentList, clientLanguage, isLoadingList }) => {

  const { t } = useTranslation();
  const location = useLocation();
  const [isNextAppointmentSelected, setIsNextAppointmentSelected] = useState(true);
  const currentList = isNextAppointmentSelected ? nextAppointmentList : pastAppointmentList;

  const handleCTAClick = () => {
    trackEvent('cta_click', {
      ctaName: 'Refresh appointment list',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
  };

  const refreshClicked = () => {
    refreshAppointmentList();
    handleCTAClick();
  };

  const cancelAppoitmentClicked = useCallback((appointment) => {
    setCurrentAppointment(appointment);
    handleClickOpen(appointment);
  }, [handleClickOpen, setCurrentAppointment]);

  return <>
    <AppointmentTabs clientLanguage={clientLanguage} isNextAppointmentSelected={isNextAppointmentSelected} setIsNextAppointmentSelected={setIsNextAppointmentSelected} />
    <div className={clientLanguage === "ar" ? styles.refreshContainerRTL : styles.refreshContainerLTR}>
      <div className={clientLanguage === "ar" ? styles.logoContainerRTL : styles.logoContainerLTR} onClick={refreshClicked}>
        <img src={refresh} alt="Refresh" className={styles.logo}></img>
      </div>
      <div onClick={refreshClicked}>
        <p className={styles.refreshListLabel}>{t("REFRESH LIST")}</p>
      </div>
    </div>
    <AppointmentListContent isLoadingList={isLoadingList} currentList={currentList} isNextAppointmentSelected={isNextAppointmentSelected} cancelAppoitmentClicked={cancelAppoitmentClicked} clientLanguage={clientLanguage} />
  </>
};

export default React.memo(AppointmentList);