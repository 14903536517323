import React from 'react';
import styles from './HeaderLogged.module.css';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import myProfile from './../../styles/Images/headerDesktop/myProfile.png';
import trackEvent from './../../utils/trackEvent';
import { getPageName } from './../../utils/Utils';

const HeaderLogged = () => {
  const location = useLocation();

  const handleHeaderClick = () => {
    trackEvent('header_click', {
      ctaName: 'Account',
      pageName: getPageName(location.pathname),
    });
  };

  const isAuth = useSelector((state) => state.config.isAuth);
  const { returnHomeUrl, returnProfileUrl } = useSelector((state) => state.config.clientInfos);
  const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
  const homeUrl = returnHomeUrl || 'https://www.chaumet.com';
  const profileUrl = returnProfileUrl || 'https://www.chaumet.com';

  return <div className={styles.headerContentLogged}>
    <div className={clientLanguage === "ar" ? styles.arrowBackRightSpaceRTL : styles.arrowBackRightSpaceLTR}>
    </div>
    <div className={clientLanguage === "ar" ? styles.logoContainerRTL : styles.logoContainerLTR}>
      <a href={homeUrl} ><img src="assets/logoMain.png" alt="Chaumet" className={clientLanguage === "ar" ? styles.logoRTL : styles.logoLTR}></img></a>
    </div>
    <div className={clientLanguage === "ar" ? styles.ProfileIconContainerRTL : styles.ProfileIconContainerLTR}>
      {isAuth && <a href={profileUrl} onClick={() => handleHeaderClick()}><img src={myProfile} alt="Chaumet" className={clientLanguage === "ar" ? styles.myProfileIconRTL : styles.myProfileIconLTR}></img></a>}
    </div>
  </div>

}

export default React.memo(HeaderLogged);