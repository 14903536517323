import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '../../store/config';
import styles from './AppointmentForm.module.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getCountriesStore, getTimeSlots, createAppointment } from '../../services/salesforce/index';
import CustomRadioButton from '../../UI/CustomRadioButton/CustomRadioButton';
import SimpleDropDown from '../../UI/SimpleDropdown/SimpleDropDown';
import CalendarDropDown from '../../UI/CalendarDropDown/CalendarDropDown';
import ConfirmButton from '../../UI/ConfirmButton/ConfirmButton';
import CancelButton from '../../UI/CancelButton/CancelButton';
import SimpleTextArea from '../../UI/SimpleTextArea/SimpleTextArea';
import { radiobuttonOptions, countryCodeIndex } from '../../services/data';
import Loading from './../../components/Loading/Loading';
import { getFomatTime, getLastItemOfObject, getDateFormatNoTZUTC, isSamedates, getDateFromDateTime } from '../../utils/Utils';
import LoadingOverlay from '../../UI/LoadingOverlay/LoadingOverlay';
import { isEmpty } from '../../utils/formUtils';
import ErrorMessage from '../../UI/ErrorMessage/ErrorMessage';
import ModalLeaveWithoutSaving from './../Modal/ModalLeaveWithoutSaving/ModalLeaveWithoutSaving';
import ModalWebsiteInMaintenance from './../Modal/ModalWebsiteInMaintenance/ModalWebsiteInMaintenance';
import InfosMessage from './../../UI/InfosMessage/InfosMessage';
import trackEvent from './../../utils/trackEvent';
import { getPageName } from './../../utils/Utils';

let isFirstLoad = true;

const AppointmentForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const clientInfos = useSelector((state) => state.config.clientInfos);
  const { clientId, storeCode, clientCountryCode, subjects } = clientInfos;
  const lng = useSelector((state) => state.config.lng);
  const appointmentParams = useSelector((state) => state.config.appointmentParams);
  const isAuth = useSelector((state) => state.config.isAuth);
  const formatTime = getFomatTime(lng);
  const [subject, setSubject] = useState(appointmentParams && appointmentParams.subject);
  const [modality, setModality] = useState(appointmentParams && appointmentParams.modality ? appointmentParams.modality : 'people');
  const [countriesList, setCountriesList] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const [timeSlots, setTimeSlots] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [currentTimeSlots, setCurrentTimeSlots] = useState([]);
  const [selectedDateFormat, setSelectedDateFormat] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const inputRefs = useRef([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [validationState, setValidationState] = useState({
    countryError: false,
    commentError: false,
    startTimeError: false
  });
  const errorStatuses = ['errorTimeSlotAlreadyTake', 'errorFieldMissing', 'errorCaptcha', 'errorStoreNotFound', 'errorTimeSlotUnavailable'];
  const [errorMsg, setErrorMsg] = useState('There was an unexpected error with saving your appointment.');
  const [errorMsgMaintenance, setErrorMsgMaintenance] = useState({ title: '', body: '' });
  const [inputIsHidden, setInputIsHidden] = useState(true);
  const [isShowModalLate, setIsShowModalLate] = useState(false);
  const [isShowModalLeave, setIsShowModalLeave] = useState(false);
  const [isShowModalMaintenance, setIsShowModalMaintenance] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const isUpdate = clientInfos.landingPage === 'update';
  const [isConfirmButtonEnable, setIsConfirmButtonEnable] = useState(!isUpdate);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState();
  const [selectedTime, setSelectedTime] = useState(appointmentParams.startTime);

  let subjectOptions = subjects && subjects.map(subject => ({
    ...subject,
    value: subject.label
  })).sort((a, b) => a.position - b.position);

  useEffect(() => {
    getCountriesAndStores();
  }, []);

  useEffect(() => {
    filterTimeSlots(selectedDateFormat);
  }, [timeSlots, selectedDateFormat, lng]);

  useEffect(() => {
    checkIfShowLateModale(selectedCountryCode);
  }, [lng, selectedCountry, selectedCountryCode]);

  useEffect(() => {
    if (timeSlots && selectedTerritoryId) {
      const selectedStoreCode = storesList.find(s => s.value === selectedTerritoryId)?.storeCode;
      updateTimeSlot(selectedTerritoryId, selectedStoreCode);
    }
  }, [storesList, selectedStore, selectedTerritoryId, selectedTimeZone]);

  useEffect(() => {
    return () => {
      isFirstLoad = true;
    };
  }, []);

  const handleCTAClick = isLogged => {
    trackEvent('cta_click', {
      ctaName: isLogged ? 'Create appointment' : 'Continue',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
  };

  const handleCTAError = errorName => {
    trackEvent('error', {
      errorName: errorName
    });
  };

  const handleCTAClickManage = isUpdate => {
    trackEvent('cta_click', {
      ctaName: isUpdate ? 'Back' : 'Manage appointment',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
  };

  const resetSelectedTime = () => {
    if (isUpdate && currentTimeSlots && currentTimeSlots.length > 0) {
      setSelectedTime();
    }
  };

  const processTimeSlotsResponse = (responseTimeSlots) => {
    if (responseTimeSlots.success === true && responseTimeSlots.data.length > 0) {
      const timeSlotsCopy = structuredClone(responseTimeSlots.data[0]);
      setTimeSlots(timeSlotsCopy);
      return timeSlotsCopy;
    }
    return null;
  };

  const updateSelectedDateFormat = (responseTimeSlotsData, appointmentStartTime, currentStoreCode) => {
    let lastKey;
    if (isUpdate && isFirstLoad && appointmentStartTime && currentStoreCode === storeCode) {
      lastKey = getDateFromDateTime(appointmentStartTime);
    } else {
      lastKey = getLastItemOfObject(responseTimeSlotsData);
    }
    setSelectedDateFormat(lastKey);
  };

  const filterTimeSlots = value => {
    setSelectedDateFormat(value);
    const matchingArray = timeSlots[value] || [];
    const timesOptions = matchingArray.map(({ startTime }) => ({
      value: startTime,
      label: getDateFormatNoTZUTC(startTime, formatTime)
    }));
    setCurrentTimeSlots(timesOptions);
  };

  const setStoresListData = (value, list = countriesList, currentStore) => {
    const selectedOptions = list.find(option => option.label === value);
    if (!selectedOptions) {
      setStoresList([]);
      return;
    }

    const updatedStores = selectedOptions.value.map(obj => ({
      label: obj.Name,
      value: obj.Id,
      TimeZone: obj.TimeZone,
      storeCode: obj.StoreCode
    }));

    // Ne gérer la sélection que lors du premier chargement
    if (isFirstLoad) {
      const storeToSelect = currentStore
        ? updatedStores.find(store => store.value === currentStore)
        : updatedStores[0];

      if (storeToSelect) {
        setStoresList(updatedStores);
        setSelectedStore(storeToSelect.value);
        setSelectedTimeZone(storeToSelect.TimeZone);
        setSelectedTerritoryId(storeToSelect.value);
      }
    } else {
      // Sinon juste mettre à jour la liste
      setStoresList(updatedStores);
    }
  };

  const getCountriesAndStores = async () => {
    setIsLoading(true);
    const responseCountries = await getCountriesStore();
    if (responseCountries.success === true) {
      const countryLabel = appointmentParams?.country ||
        (clientCountryCode ? countryCodeIndex[clientCountryCode] : undefined);

      const responseCountriesData = responseCountries.data;
      const countriesList = setCountriesListData(responseCountriesData);

      if (countriesList && responseCountriesData && countryLabel) {
        const firstCountry = countriesList.find((country) => country.label === countryLabel);
        if (firstCountry?.value?.length > 0) {
          setSelectedTimeZone(firstCountry.value[0].TimeZone);
          setSelectedCountry(firstCountry.label);
          setSelectedCountryCode(firstCountry.label);

          let currentStore;
          if (appointmentParams?.store) {
            const matchingStore = firstCountry.value.find(
              (store) => store.Id === appointmentParams.store
            );
            currentStore = matchingStore?.Id;
          }

          setSelectedStore(currentStore);
          setStoresListData(countryLabel, countriesList, currentStore);
        }
      }
    }
    setIsLoading(false);
  };

  const setCountriesListData = countriesList => {
    let countriesOptions = [];
    Object.keys(countriesList).forEach((key, index) => {
      countriesOptions.push({
        value: countriesList[key],
        label: key
      });
    });
    const sortStores = (stores, storeCode) => {
      const matchingIndex = stores.findIndex(store => store.StoreCode === storeCode);

      if (matchingIndex !== -1) {
        // Case 1: If there is a storeCode in the stores.StoreCode list that matches, sort by a.StoreCode === storeCode
        return stores.slice().sort((a, b) => {
          if (a.StoreCode === storeCode && b.StoreCode !== storeCode) return -1;
          if (b.StoreCode === storeCode && a.StoreCode !== storeCode) return 1;
          return 0;
        });
      } else {
        // Case 2: If there are no stores.StoreCode properties matching the storeCode, sort by IsFlagship
        return stores.slice().sort((a, b) => b.IsFlagship - a.IsFlagship);
      }
    };
    [...countriesOptions].forEach((country) => {
      country.value = storeCode ? sortStores(country.value, storeCode) : [...country.value].sort((a, b) => b.IsFlagship - a.IsFlagship);
    });
    setCountriesList(countriesOptions);
    return countriesOptions;
  };

  const handleRef = useCallback((name, ref) => {
    inputRefs.current[name] = ref;
  }, []);

  const handleModalityChange = useCallback((selectedModality) => {
    setModality(selectedModality);
    setIsConfirmButtonEnable(true);
  }, []);

  const handleCancelButtonClicked = useCallback(() => {
    if (isUpdate && isConfirmButtonEnable) {
      setIsShowModalLeave(true);
    }
    else {
      handleCTAClickManage(isUpdate);
      history.push({
        pathname: isUpdate ? '/appointmentResume' : '/appointmentList'
      });
    }
  }, [history, isConfirmButtonEnable, isUpdate]);

  const createFormData = useCallback(() => {
    const selectedOptions = storesList.find(option => option.value === inputRefs.current.store.value);
    let selectedCountry;
    if (selectedOptions) {
      selectedCountry = countriesList.find(country => {
        return country.value.some(val => val.Id === selectedOptions.value);
      });
    }
    const dateTimeSlot = timeSlots[selectedDateFormat] || [];
    const timeSlot = dateTimeSlot.find(timeSlot => {
      return isSamedates(timeSlot.startTime, inputRefs.current.time.value)
    });
    const subjectPosition1 = subjectOptions.find(option => option.position === 1);
    const formValues = {
      subject: subject || subjectPosition1.value,
      comment: inputRefs.current.comment.value,
      modality: modality,
      storeLabel: selectedOptions && selectedOptions.label,
      store: selectedOptions && selectedOptions.value,
      country: selectedCountry && selectedCountry.label,
      startTime: timeSlot && timeSlot.startTime,
      endTime: timeSlot && timeSlot.endTime,
      selectedTimeZone: selectedTimeZone,
      lng: lng
    };
    if (isUpdate) {
      formValues.uuid = appointmentParams.uuid;
      formValues.isAppointmentDone = true;
    }
    return formValues;
  }, [countriesList, modality, selectedDateFormat, selectedTimeZone, storesList, timeSlots, subject, lng]);

  const createNewAppointment = useCallback(async (formValues) => {
    setIsLoading(true);
    try {
      const response = await createAppointment(formValues);
      if (response.success === true) {
        setIsLoading(false);
        if (response.data.data.status && response.data.data.status === "error") {
          setInputIsHidden(false);
          handleCTAError('error when trying to create an appointment');
        }
        else if (response.data.data.status && response.data.status === "insert failed") {
          setErrorMsgMaintenance({ title: response.data.status, body: response.data.errorReason });
          setIsShowModalMaintenance(true);
          setErrorMsg(response.data.data.errorReason);
          setInputIsHidden(false);
          handleCTAError(response.data.data.errorReason);
        }
        else if (response.data.data.status && errorStatuses.includes(response.data.data.status)) {
          setErrorMsg(response.data.data.errorReason);
          setInputIsHidden(false);
          handleCTAError(response.data.data.errorReason);
        }
        else {
          trackEvent('form_completed', {
            contactTheme: 'Create appointment'
          });
          if (isAuth || isUpdate) {
            setInputIsHidden(true);
            history.push({
              pathname: '/appointmentResume',
              state: formValues
            });
          }
        }
      }
      else {
        setInputIsHidden(true);
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      setInputIsHidden(true);
      handleCTAError('unknow error when trying to create an appointment');
    }
  }, [isAuth, history, errorStatuses]);

  const checkDataValidity = useCallback(() => {
    let formIsValid = true;
    setIsFormSubmitted(true);
    const formValues = createFormData();
    const newValidationState = {
      countryError: isEmpty(formValues.country),
      startTimeError: isEmpty(formValues.startTime),
      ...(subject === 'Other services' ? { commentError: isEmpty(formValues.comment) } : {})
    };
    setValidationState(newValidationState);
    formIsValid = !Object.values(newValidationState).some(error => error);
    if (formIsValid) {
      if (isAuth || isUpdate) {
        handleCTAClick(true);
        formValues.accountId = clientId
        createNewAppointment(formValues);
      }
      else {
        handleCTAClick(false);
        dispatch(configActions.setAppointmentParams(formValues));
        history.push({
          pathname: '/contactDetails'
        });
      }
    }
  }, [clientId, isAuth, createNewAppointment, dispatch, history, createFormData]);

  const checkValidationForm = () => {
    const newValidationState = {
      ...validationState,
      ...(subject === 'Other services' ? { commentError: isEmpty(inputRefs.current.comment.value) } : {})
    };
    setValidationState(newValidationState);
  };

  const handleSubjectOptionChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
    setSubject(value.value);
    checkValidationForm();
  }, []);

  const handleCommentChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
  }, []);

  const handleCountryOptionChange = useCallback((name, value) => {
    const selectedOptions = countriesList.find(option => option.label === value);
    if (selectedOptions) {
      // 1. Reset complet avant tout changement
      setTimeSlots({});
      setCurrentTimeSlots([]);
      setSelectedTimeSlot(undefined);
      setSelectedDateFormat('');
      setSelectedTime(null);
      setSelectedStore('');
      setSelectedTimeZone('');
      setSelectedTerritoryId(null);

      // 2. Mettre à jour le pays
      setSelectedCountry(value);
      setSelectedCountryCode(value);

      // 3. Préparer les stores
      const updatedStores = selectedOptions.value.map(obj => ({
        label: obj.Name,
        value: obj.Id,
        TimeZone: obj.TimeZone,
        storeCode: obj.StoreCode
      }));

      // 4. Toujours prendre le premier store lors d'un changement de pays
      const defaultStore = updatedStores[0];

      // 5. Mettre à jour le store AVANT la liste
      if (defaultStore) {
        setSelectedStore(defaultStore.value);
        setSelectedTimeZone(defaultStore.TimeZone);
        setSelectedTerritoryId(defaultStore.value);
      }

      // 6. Mettre à jour la liste en dernier
      setStoresList(updatedStores);
    }
  }, [countriesList]);

  const handleStoreOptionChange = useCallback((name, value) => {
    if (value && value.value) {
      // 1. D'abord mettre à jour le store
      setSelectedStore(value.value);
      setSelectedTimeZone(value.TimeZone);
      setSelectedTerritoryId(value.value);

      // 2. Ensuite reset les time slots
      // Le useEffect se déclenchera avec les nouvelles valeurs
      setTimeSlots({});
      setCurrentTimeSlots([]);
      setSelectedTimeSlot(undefined);
      setSelectedDateFormat('');
      setSelectedTime(null);
    }
  }, []);

  const handleTimeOptionChange = useCallback((name, value) => {
    if (isUpdate) {
      const selectedStartTime = value?.value || value;
      const initialStartTime = appointmentParams?.startTime;
      setIsConfirmButtonEnable(selectedStartTime !== initialStartTime);
    } else {
      setIsConfirmButtonEnable(true);
    }
    setSelectedTime(value?.value || value);
  }, [isUpdate, appointmentParams]);

  const handleCalendarDropDownChange = useCallback((name, value) => {
    filterTimeSlots(value);
    setIsConfirmButtonEnable(true);
    resetSelectedTime();
  }, [filterTimeSlots]);

  const handleConfirmButtonClicked = useCallback(() => {
    checkDataValidity();
  }, [checkDataValidity]);

  const handleContinueToContactDetailButtonClicked = useCallback(() => {
    checkDataValidity();
  }, [checkDataValidity]);

  const checkIfShowLateModale = selectedCountry => {
    if (selectedCountry === 'Japan') {
      setIsShowModalLate(true);
    }
    else {
      setIsShowModalLate(false);
    }
  };

  const handleCloseModalLate = useCallback(() => {
    setIsShowModalLate(false);
  }, [checkDataValidity]);

  const handleCloseModalMaintenance = useCallback(() => {
    setIsShowModalMaintenance(false);
  }, []);

  const confirmButtonTitle = () => {
    if (isAuth) {
      return 'CONFIRM THIS APPOINTMENT';
    }
    else if (isUpdate) {
      return 'SAVES CHANGES';
    }
    else {
      return 'CONTINUE TO CONTACT DETAILS';
    }
  };

  const confirmButtonAction = () => {
    if (isAuth || isUpdate) {
      handleConfirmButtonClicked();
    }
    else {
      handleContinueToContactDetailButtonClicked()
    }
  };

  const handleConfirmModalLeave = useCallback(() => {
    setIsShowModalLeave(false);
  }, []);

  const handleCancelModalLeave = useCallback(() => {
    setIsShowModalLeave(false);
    history.push({
      pathname: isUpdate ? '/appointmentResume' : '/appointmentList'
    });
  }, []);

  const updateTimeSlot = async (territoryId) => {
    if (!territoryId) return;

    const currentStore = storesList.find(store => store.value === territoryId);
    const currentStoreCode = currentStore?.storeCode;

    setIsRefreshing(true);
    try {
      const responseTimeSlots = await getTimeSlots(territoryId);
      const timeSlotsCopy = processTimeSlotsResponse(responseTimeSlots);

      if (timeSlotsCopy) {
        const isInitialStore = isUpdate && appointmentParams?.startTime && currentStoreCode === storeCode;

        if (isInitialStore) {
          if (isFirstLoad) {
            setSelectedTime(appointmentParams.startTime);
            setSelectedTimeSlot(appointmentParams.startTime);
          }

          const formattedDate = getDateFromDateTime(appointmentParams.startTime);
          const currentDateTimeToInsert = {
            duration: appointmentParams.duration,
            endTime: appointmentParams.endTime,
            remainingAppointments: "1",
            startTime: appointmentParams.startTime,
            territoryId: appointmentParams.territoryId,
          };

          if (timeSlotsCopy[formattedDate]) {
            const existingSlot = timeSlotsCopy[formattedDate].find(
              (slot) => slot.startTime === appointmentParams.startTime
            );
            if (!existingSlot) {
              timeSlotsCopy[formattedDate].push(currentDateTimeToInsert);
              timeSlotsCopy[formattedDate].sort(
                (a, b) => new Date(a.startTime) - new Date(b.startTime)
              );
            }
          } else {
            timeSlotsCopy[formattedDate] = [currentDateTimeToInsert];
          }
        }

        setTimeSlots(timeSlotsCopy);
        updateSelectedDateFormat(
          responseTimeSlots.data[0],
          isFirstLoad && isInitialStore ? appointmentParams.startTime : null,
          currentStoreCode
        );
        if (isFirstLoad) isFirstLoad = false;
      }
    } finally {
      setIsRefreshing(false);
    }
  };

  return <div className={styles.container}>
    <LoadingOverlay isLoading={isRefreshing} />
    <div className={styles.containerForm}>
      <div className={styles.titleLabelContainer}>
        <p className={styles.titleLabel}>{t(isUpdate ? 'EDIT THE APPOINTMENT' : 'BOOK AN APPOINTMENT')}</p>
        <p className={styles.titleSubLabel}>{t("All the fields are mandatory")}</p>
      </div>
      {isLoading || countriesList.length === 0 ? <Loading /> :
        <>{!isAuth && <div className={styles.titleLabelSectionContainer}>
          <p className={styles.titleLabelSectionOptional}>1. {t("APPOINTMENT DETAILS")}</p>
        </div>}
          <div className={styles.titleLabelSectionContainer}>
            <p className={styles.titleLabelSection}>{t("REASON OF YOUR VISIT")}</p>
          </div>
          <div className={styles.dropDownContainerNotDivided}>
            <SimpleDropDown name="subject" options={subjectOptions || []} title={t("")} preselectedValue={subject || undefined} handleRef={handleRef} handleChange={handleSubjectOptionChange} returnValue={true} />
          </div>
          <SimpleTextArea title={t("COMMENTS")} name='comment' preselectedValue={appointmentParams.comment || undefined} placeholder={t("Please write if you have complementary comments")} handleRef={handleRef} handleChange={handleCommentChange} validationError={isFormSubmitted && validationState.commentError} />
          <div className={lng === "ar" ? styles.dropDownContainerRTL : styles.dropDownContainerLTR}>
            <SimpleDropDown name="country" options={countriesList} title={t("COUNTRY / REGION")} preselectedValue={selectedCountry} handleRef={handleRef} handleChange={handleCountryOptionChange} isEmptyValue={selectedCountry ? false : true} validationError={isFormSubmitted && validationState.countryError} />
            <SimpleDropDown name="store" options={storesList} title={t("STORE")} preselectedValue={selectedStore} handleRef={handleRef} handleChange={handleStoreOptionChange} returnValue={true} />
          </div>
          <div className={styles.dropDownParentContainer}>
            <div className={lng === "ar" ? styles.dropDownContainerRTL : styles.dropDownContainerLTR}>
              <CalendarDropDown name="date" title={t("DATE")} preselectedValue={selectedTimeSlot || undefined} handleRef={handleRef} dateList={timeSlots} clientLanguage={lng} handleCalendarDropDownChange={handleCalendarDropDownChange} />
              <SimpleDropDown name="time" options={currentTimeSlots} title={t("TIME")} subTitle={selectedTimeZone} preselectedValue={selectedTime} handleRef={handleRef} handleChange={handleTimeOptionChange} returnValue={true} />
            </div>
            <InfosMessage isDisplay={isShowModalLate} clientLanguage={lng} />
            <CustomRadioButton name="modality" options={radiobuttonOptions} title={t("MODALITY")} preselectedValue={modality || undefined} handleRef={handleRef} handleRadioOptionChange={handleModalityChange} />
            {isAuth && <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />}
            <div className={styles.buttonBlockContainer}>
              <ConfirmButton title={t(confirmButtonTitle())} handleButtonClicked={confirmButtonAction} isEnable={isConfirmButtonEnable} />
            </div>
            {(isAuth || isUpdate) && <CancelButton title={t(isUpdate ? 'CANCEL CHANGES' : 'BACK TO MY APPOINTMENTS')} handleButtonClicked={handleCancelButtonClicked} />}
            {!isAuth && !isUpdate && <div className={styles.titleLabelSectionContainerOptional}>
              <p className={styles.titleLabelSectionOptional}>2. {t("CONTACT DETAILS")}</p>
            </div>}
          </div>
          <ModalLeaveWithoutSaving open={isShowModalLeave} onClose={handleConfirmModalLeave} clientLanguage={lng} confirmButtonPressed={handleConfirmModalLeave} deleteButtonPressed={handleCancelModalLeave} />
          <ModalWebsiteInMaintenance open={isShowModalMaintenance} onClose={handleCloseModalMaintenance} clientLanguage={lng} title={errorMsgMaintenance.title} body={errorMsgMaintenance.body} />
        </>}
    </div>
  </div >
};

export default React.memo(AppointmentForm);