import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './../pages/Home';
import GuestContactDetails from './../pages/GuestContactDetails';
import AppointmentResume from './../pages/AppointmentResume';
import CancelAppointment from './../pages/CancelAppointment';
import LoggedAppointmentList from './../pages/LoggedAppointmentList';
import AddToMyCalendar from './../pages/AddToMyCalendar';
import trackEvent from './../utils/trackEvent';
import { getPageName } from './../utils/Utils';
import sha256 from 'crypto-js/sha256';

const AppRoutes = () => {
    const history = useHistory();
    const location = useLocation();
    const { lng, clientInfos, isAuth } = useSelector((state) => state.config);
    const { clientCountryCode, email, clientEmail, crmId, clientCrmId } = clientInfos;

    useEffect(() => {
        const handlePopState = () => {
            const currentPath = location.pathname;
            if (!isAuth && currentPath === '/appointmentResume') {
                history.replace('/');
            }
        };

        window.onpopstate = handlePopState;

        return () => {
            window.onpopstate = null;
        };
    }, [history, location, isAuth]);

    useEffect(() => {
        const pageName = location.pathname;
        const websiteCountry = clientCountryCode || lng;
        const mail = isAuth ? clientEmail : email;
        const userId = crmId || clientCrmId || '';

        const pageParams = {
            page_Name: getPageName(location.pathname),
            website_country: websiteCountry.toUpperCase(),
            website_language: lng,
            page_type: getPageType(pageName),
            page_top_category: '',
            page_mid_category: '',
            hashed_email: mail ? sha256(mail).toString() : '',
            user_id: userId,
            login_status: isAuth ? 'Logged' : 'Not logged',
        };
        trackEvent('page_view', pageParams);

    }, [location]);

    const getPageType = (pathname) => {
        switch (pathname) {
            case '/':
                return 'home';
            case '/addtocalendar':
                return 'add_to_calendar';
            case '/appointmentList':
                return 'appointment_list';
            case '/contactDetails':
                return 'contact_details';
            case '/appointmentResume':
                return 'appointment_resume';
            case '/appointmentCancel':
                return 'appointment_cancel';
            default:
                return 'other';
        }
    };

    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/addtocalendar" component={AddToMyCalendar} />
            <Route exact path="/appointmentList" component={LoggedAppointmentList} />
            <Route exact path="/contactDetails" component={GuestContactDetails} />
            <Route exact path="/appointmentResume" component={AppointmentResume} />
            <Route exact path="/appointmentCancel" component={CancelAppointment} />
        </Switch>
    );
};

export default AppRoutes;
