import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '../../../store/config';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './AppointmentCreated.module.css';
import ThanksYou from '../../../UI/ThanksYou/ThanksYou';
import DetailInfoItem from '../../../UI/DetailInfoItem/DetailInfoItem';
import CancelButton from '../../../UI/CancelButton/CancelButton';
import CancelHeader from '../../../components/Header/CancelHeader/CancelHeader';
import { getDateFormat, getDateFormatNoTZUTC, getFomatDateShort, getFomatTime } from '../../../utils/Utils';
import { getLabelFromValue } from './../../../services/data';
import ConfirmButton from '../../../UI/ConfirmButton/ConfirmButton';
import ModalSureToDelete from './../../Modal/ModalSureToDelete/ModalSureToDelete';
import LoaderRequest from './../../Loading/LoaderRequest/LoaderRequest';
import ModalCancelAppointmentDone from './../../Modal/ModalCancelAppointmentDone/ModalCancelAppointmentDone';
import { cancelAppointment } from './../../../services/salesforce/index';
import trackEvent from './../../../utils/trackEvent';
import { getPageName } from './../../../utils/Utils';

const AppointmentCreated = ({ userData = {}, isCalendar = false }) => {
  const { storeLabel, startTime, endTime, selectedTimeZone, country, email, salutation, firstName, lastName, countryCode, mobile, subject, comment, modality, landingPage, timeZone, storeCode, uuid, duration, territoryId } = userData;
  const lng = useSelector((state) => state.config.lng);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const fullName = t(salutation) + ' ' + firstName + ' ' + lastName;
  const phone = landingPage !== 'cancel' ? countryCode + ' ' + mobile : mobile;
  const clientInfos = useSelector((state) => state.config.clientInfos);
  const { clientEmail, clientName, clientPhone } = useSelector((state) => clientInfos);
  const isAuth = useSelector((state) => state.config.isAuth);
  const name = isAuth ? clientName : fullName;
  const isUpdate = landingPage === 'update';
  const currentLng = useSelector((state) => state.config.lng);
  const modalityLabel = getLabelFromValue(modality);
  const formatTime = getFomatTime(currentLng);
  const formatDate = getFomatDateShort(currentLng);
  const dateLabel = landingPage === 'cancel' || landingPage === 'calendar' ? getDateFormat(startTime, formatDate, timeZone) : getDateFormatNoTZUTC(startTime, formatDate);
  const timeLabel = landingPage === 'cancel' || landingPage === 'calendar' ? getDateFormat(startTime, formatTime, timeZone) + ' - ' + getDateFormat(endTime, formatTime, timeZone) : getDateFormatNoTZUTC(startTime, formatTime) + ' - ' + getDateFormatNoTZUTC(endTime, formatTime);
  const TimeZoneSubtitle = landingPage === 'update' ? timeZone : selectedTimeZone;
  const optionsList = [
    { title: 'FULL NAME', value: name || '-' },
    { title: 'EMAIL', value: isAuth ? clientEmail : email || '-' },
    { title: 'PHONE', value: isAuth ? clientPhone : phone || '-' },
    { title: 'REASON', value: subject || '-' },
    comment ? { title: 'COMMENTS', value: comment || '-' } : null,
    { title: 'MODALITY', value: modalityLabel || '-' },
    { title: 'STORE', value: `${storeLabel} (${country})` || '-' },
    { title: 'DATE', value: dateLabel || '-' },
    { title: 'TIME', subtitle: `- ${TimeZoneSubtitle}`, value: timeLabel || '-' }
  ].filter(item => item !== null);
  const isDataEmpty = Object.keys(userData).length === 0;
  const nameToDisplay = isDataEmpty ? '' : isAuth ? clientName : firstName;
  const noDataBody = 'Thank you for making an appointment, you will receive a confirmation email soon.';
  const ConfirmButtonTitle = isUpdate ? 'EDIT APPOINTMENT' : 'BOOK A NEW APPOINTMENT';
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [isLoadingPopUp, setIsLoadingPopUp] = useState(false);
  const [isLoadingPopUpDone, setIsLoadingPopUpDone] = useState(false);
  const [isCancelAppointmentDone, setIsCancelAppointmentDone] = useState(false);

  useEffect(() => {
    dispatch(configActions.setContactDetailsParams({}));
  }, [dispatch]);

  const backToAppointmentsListClicked = useCallback(() => {
    trackEvent('cta_click', {
      ctaName: 'Back to manage',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
    history.push({
      pathname: '/appointmentList'
    });
  }, [history]);

  const backToCreateAppointmentsClicked = useCallback(() => {
    if (isUpdate) {
      const formValues = {
        subject: subject,
        comment: comment,
        modality: modality,
        storeLabel: storeLabel,
        store: territoryId,
        country: country,
        startTime: startTime,
        endTime: endTime,
        selectedTimeZone: timeZone,
        lng: lng,
        uuid: uuid,
        territoryId: territoryId,
        duration, duration
      };
      dispatch(configActions.setAppointmentParams(formValues));
    }
    trackEvent('cta_click', {
      ctaName: 'Back to home',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
    history.push({
      pathname: '/'
    });
  }, [history]);

  const openModalCancelClicked = useCallback(() => {
    setOpenModalCancel(true);
  }, []);

  const handleCloseModalCancel = useCallback(() => {
    setOpenModalCancel(false);
  }, []);

  const handleCloseLoader = () => {
    setIsLoadingPopUp(false);
  };

  const handleCloseModalAppDone = () => {
    setIsLoadingPopUpDone(false);
  };

  const confirmCancelAppointment = useCallback(async () => {
    setOpenModalCancel(false);
    setIsLoadingPopUp(true);
    trackEvent('cta_click', {
      ctaName: 'Cancel appointment',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
    const data = { uuid: userData.uuid };
    const response = await cancelAppointment(data);
    if (response.success === true) {
      setIsLoadingPopUpDone(true);
      setIsCancelAppointmentDone(true);
    }
    setIsLoadingPopUp(false);
  }, [userData]);

  const renderHeader = () => {
    if (landingPage === 'cancel') {
      return <><CancelHeader /></>;
    }
    else if (landingPage === 'update') {
      return <div className={styles.headerUpdate}>{t('YOUR APPOINTMENT DETAILS')}</div>;
    }
    else {
      return <ThanksYou clientName={nameToDisplay} subtitle={isCalendar ? 'We are looking forward to meeting you' : undefined} />;
    }
  };

  return <div className={styles.container}>
    {renderHeader()}
    <div className={isUpdate ? styles.containerInfosDetailsUpdate : styles.containerInfosDetails}>
      <div className={styles.containerInfosDetailsTitle}>
        <p className={styles.containerInfosDetailsTitleLabel}>{t(landingPage === 'cancel' ? 'APPOINTMENT DETAILS' : 'APPOINTMENT CONFIRMATION')}</p>
      </div>
      <div className={styles.containerInfosDetailsTitleLine}>
      </div>
      {isDataEmpty ?
        <>
          <div className={styles.noDataTitleLabel}><p>{t(noDataBody)}</p></div>
          <ConfirmButton title={t(ConfirmButtonTitle)} handleButtonClicked={backToCreateAppointmentsClicked} />
        </>
        :
        optionsList && optionsList.length > 0 && optionsList.map(item => <DetailInfoItem key={item.title} title={item.title} subtitle={item.subtitle} value={item.value} withMargin={false} />)}
    </div>
    {isAuth && <CancelButton title={t('VIEW ALL MY APPOINTMENTS')} handleButtonClicked={backToAppointmentsListClicked} />}
    {isUpdate && !isCancelAppointmentDone && <ConfirmButton title={t(ConfirmButtonTitle)} handleButtonClicked={backToCreateAppointmentsClicked} />}
    {isUpdate && !isCancelAppointmentDone && <CancelButton title={t('CANCEL APPOINTMENT')} handleButtonClicked={openModalCancelClicked} />}
    <ModalSureToDelete open={openModalCancel} onClose={handleCloseModalCancel} confirmCancelAppointment={confirmCancelAppointment} selectedAppointment={userData} clientLanguage={lng} />
    <LoaderRequest open={isLoadingPopUp} onClose={handleCloseLoader} />
    <ModalCancelAppointmentDone open={isLoadingPopUpDone} onClose={handleCloseModalAppDone} selectedAppointment={userData} clientLanguage={lng} />
  </div>;
};

export default React.memo(AppointmentCreated);