import React, { useEffect } from 'react';
import styles from './AppointmentResume.module.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import AppointmentCreated from './../components/CreateAppointment/AppointmentCreated/AppointmentCreated';

const AppointmentResume = () => {

    const history = useHistory();
    const clientInfos = useSelector((state) => state.config.clientInfos);
    const isAuth = useSelector((state) => state.config.isAuth);
    const isSessionExpired = useSelector((state) => state.config.isSessionExpired);
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    let appointmentData = history.location.state;
    if (clientInfos.landingPage === 'update') {
        if (history.location.state && appointmentData.isAppointmentDone) {
            appointmentData = { ...history.location.state };
            appointmentData.firstName = clientInfos.firstName;
            appointmentData.lastName = clientInfos.lastName;
            appointmentData.email = clientInfos.email;
            appointmentData.salutation = clientInfos.salutation;
            appointmentData.mobile = clientInfos.mobile;
            appointmentData.countryCode = clientInfos.countryCode;

        }
        else {
            appointmentData = clientInfos;
        }
    }

    const handleCTAClick = () => {

    };

    useEffect(() => {
        if (appointmentData && !appointmentData.accountId) {
            handleCTAClick();
        }
    }, []);


    return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLanguage} />{isAuth ? <HeaderLogged clientLanguage={clientLanguage} /> : <HeaderGuest clientLanguage={clientLanguage} />}
            {!isSessionExpired ? <div className={clientLanguage === "ar" ? styles.containerResumeRTL : styles.containerResumeLTR}>
                <AppointmentCreated userData={appointmentData} />
            </div>
                : <SessionExpired />}
        </div>
        <div>
            <Footer />
        </div>
    </>
};

export default AppointmentResume;