import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import trackEvent from './../../utils/trackEvent';
import { getPageName } from './../../utils/Utils';
import styles from './DropdownLink.module.css';

const DropdownLink = ({ options, title, isEmptyValue = false, handleClick, width = '250px' }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const lng = useSelector((state) => state.config.lng);
    const [isListVisible, setIsListVisible] = useState(false);

    const handleCTAClick = () => {
        trackEvent('cta_click', {
            ctaName: 'Add to my calendar',
            pageName: getPageName(location.pathname),
            location_in_page: 'mid'
        });
    };

    const parentClicked = () => {
        if (!isListVisible) {
            handleCTAClick();
        }
        setIsListVisible(!isListVisible);
    };

    return (
        <div className={styles.container}>
            <div className={styles.containerLabel}>
                {isEmptyValue && (
                    <div className={styles.ListParentContainer} onClick={parentClicked}>
                        {title.length > 0 && <p className={styles.titleStyle}>{t(title)}</p>}
                    </div>
                )}
                {isListVisible && (
                    <div className={styles.dropdownList}>
                        {options.map((option) => (
                            <div className={lng === "ar" ? styles.dropdownListItemLTR : styles.dropdownListItemRTL} key={option.label}>
                                {option.isLink ? <a href={option.link} target="_blank" rel="noopener noreferrer">
                                    <div className={lng === "ar" ? styles.rowContainerRTL : styles.rowContainerLTR}>
                                        <div>
                                            {option.img && <img src={option.img} alt="icon" className={styles.iconImg}></img>}
                                        </div>
                                        <div>
                                            <p className={lng === "ar" ? styles.iconTitleRTL : styles.iconTitleLTR}>{option.label}</p>
                                        </div>
                                    </div>
                                </a>
                                    :
                                    <div onClick={handleClick}>
                                        <div className={lng === "ar" ? styles.rowContainerRTL : styles.rowContainerLTR}>
                                            <div>
                                                {option.img && <img src={option.img} alt="icon" className={styles.iconImg}></img>}
                                            </div>
                                            <div>
                                                <p onClick={handleClick} className={lng === "ar" ? styles.iconTitleRTL : styles.iconTitleLTR}>{option.label}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.borderBottom}></div>
        </div>
    );
};

export default React.memo(DropdownLink);
