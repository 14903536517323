import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styles from './AppointmentGuestResume.module.css';
import editButton from '../../../styles/Images/createAppointment/form/editButton.png';
import checkmark from '../../../styles/Images/createAppointment/form/checkmark.png';
import trackEvent from './../../../utils/trackEvent';
import { getPageName } from './../../../utils/Utils';

const AppointmentGuestResume = ({ editBtnClicked }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const lng = useSelector((state) => state.config.lng);

    const handleCTAClick = () => {
        trackEvent('cta_click', {
            ctaName: 'Edit appointment',
            pageName: getPageName(location.pathname),
            location_in_page: 'mid'
        });
    };

    const handleClickEdit = () => {
        handleCTAClick();
        editBtnClicked();
    };

    return <div className={styles.container}>
        <div className={styles.titleLabelContainer}>
            <p className={styles.titleLabel}>{t("BOOK AN APPOINTMENT")}</p>
            <p className={styles.titleSubLabel}>{t("All the fields are mandatory")}</p>
        </div>
        <div className={styles.containerInfosDetails}>
            <div className={styles.titleLabelSectionContainer}>
                <div className={lng === "ar" ? styles.checkmarkContainerRTL : styles.checkmarkContainerLTR}>
                    <div>
                        <img src={checkmark} alt="checkmark" className={lng === "ar" ? styles.iconCheckmarkRTL : styles.iconCheckmarkLTR}></img>
                    </div>
                    <div>
                        <p className={styles.titleLabelSectionOptional}>{t("APPOINTMENT DETAILS")}</p>
                    </div>
                </div>
                <div className={lng === "ar" ? styles.editContainerRTL : styles.editContainerLTR} onClick={handleClickEdit}>
                    <div><img src={editButton} alt="editIcon" className={lng === "ar" ? styles.iconEditBtnRTL : styles.iconEditBtnLTR}></img></div>
                    <div className={styles.iconEditTitle}><p className={styles.titleEditIcon}>{t("EDIT")}</p></div>
                </div>
            </div>
            <div className={styles.containerInfosDetailsTitleLine}>
            </div>
        </div>
        <div className={styles.separator}></div>
    </div>;
};

export default React.memo(AppointmentGuestResume);