export const radiobuttonOptions = [
  { value: 'people', label: 'In store' },
  { value: 'screen', label: 'Video call' },
  { value: 'phone_portrait', label: 'Phone call' },
];

export const radiobuttonYESNOOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
];

export const subjectOptions = [
  { value: 'High Jewelry', label: 'High Jewelry' },
  { value: 'Jewelry and Watches', label: 'Jewelry and Watches' },
  { value: 'Engagement and wedding ring', label: 'Engagement and wedding ring' },
  { value: 'Offer the perfect gift', label: 'Offer the perfect gift' },
  { value: 'Repair of a creation', label: 'Repair of a creation' },
  { value: 'Other services', label: 'Other services' }
];

export const clientTitleOptions = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'I prefer not to say', label: 'I prefer not to say' }
];

export const getLabelFromValue = value => {
  const option = radiobuttonOptions.find(option => option.value === value);
  return option ? option.label : '-';
};

export const countryCodeIndex = {
  fr: 'France',
  gb: 'Great Britain',
  hk: 'Hong Kong',
  ae: 'United Arab Emirates',
  au: 'Australia',
  at: 'Austria',
  be: 'Belgium',
  de: 'Germany',
  fi: 'Finland',
  ie: 'Ireland',
  it: 'Italy',
  lu: 'Luxembourg',
  nl: 'Netherlands',
  pt: 'Portugal',
  dk: 'Denmark',
  no: 'Norway',
  se: 'Sweden',
  ch: 'Switzerland',
  es: 'Spain',
  nz: 'New-Zealand',
  jp: 'Japan',
  kr: 'Korea',
  sg: 'Singapore',
  tw: 'Taiwan',
  cn: 'China',
  kw: 'Koweit',
  qa: 'Qatar',
  sa: 'Saudi Arabia',
  ru: 'Russia',
  us: 'USA',
  ca: 'Canada',
  mc: 'Monaco',
  mo: 'Macau SAR',
  my: 'Malaysia'
};

export const optionsTranslations = [
  { value: 'en_US', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'ja', label: 'Japanese' },
  { value: 'zh_TW', label: 'Chinese (Traditional)' },
  { value: 'zh_CN', label: 'Chinese (Simplified)' },
  { value: 'ko', label: 'Korean' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'ar', label: 'عربى' }
];

export const langMappings = {
  'cht': 'zh_TW',
  'zh': 'zh_CN',
  'kr': 'ko'
};

export const privacyPolicyUrls = {
  "fr": "https://www.chaumet.com/fr_fr/donnees-personnelles",
  "en_Us": "https://www.chaumet.com/gb_en/privacy-policy",
  "ja": "https://www.chaumet.com/jp_ja/privacy-policy",
  "ko": "https://www.chaumet.com/kr_kr/privacy-policy",
  "zh_CN": "https://www.chaumet.cn/cn_zh/privacy-policy",
  "zh_TW": "https://www.chaumet.com/hk_cht/privacy-policy",
  "zh-Hant-TW": "https://www.chaumet.com/tw_cht/privacy-policy",
  "ar": "https://www.chaumet.com/ae_ar/privacy-policy",
  "kr": "https://www.chaumet.com/kr_kr/privacy-policy",
  "default": "https://www.chaumet.com/gb_en/privacy-policy"
};

export const CAPTCHA_PUBLIC_KEY = '6Lf_yyQoAAAAABLm1IFjDUK2rnDmwdVskAi9oLGX';

// export const urlMappings = {
//   // France
//   fr: {
//     newsletter: 'https://www.chaumet.com/fr_fr/abonnement-newsletter',
//     contact: 'https://www.chaumet.com/fr_fr/contact'
//   },
//   // Great Britain
//   gb: {
//     newsletter: 'https://www.chaumet.com/gb_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/gb_en/contact'
//   },
//   // Hong Kong
//   hk: {
//     newsletter: 'https://www.chaumet.com/hk_cht/newsletter-subscription',
//     contact: 'https://www.chaumet.com/hk_cht/contact'
//   },
//   // UAE
//   ae: {
//     newsletter: 'https://www.chaumet.com/ae_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/ae_en/contact'
//   },
//   // Australia
//   au: {
//     newsletter: 'https://www.chaumet.com/au_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/au_en/contact'
//   },
//   // EU Countries (Austria, Belgium, Germany, Finland, Ireland, Italy, Luxembourg, Netherlands, Portugal)
//   at: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   be: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   de: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   fi: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   ie: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   it: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   lu: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   nl: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   pt: {
//     newsletter: 'https://www.chaumet.com/eu_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/eu_en/contact'
//   },
//   // Nordic Countries
//   dk: {
//     newsletter: 'https://www.chaumet.com/dk_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/dk_en/contact'
//   },
//   no: {
//     newsletter: 'https://www.chaumet.com/no_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/no_en/contact'
//   },
//   se: {
//     newsletter: 'https://www.chaumet.com/se_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/se_en/contact'
//   },
//   // Switzerland
//   ch: {
//     newsletter: 'https://www.chaumet.com/ch_fr/abonnement-newsletter',
//     contact: 'https://www.chaumet.com/ch_fr/contact'
//   },
//   // Spain
//   es: {
//     newsletter: 'https://www.chaumet.com/es_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/es_en/contact'
//   },
//   // New Zealand
//   nz: {
//     newsletter: 'https://www.chaumet.com/nz_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/nz_en/contact'
//   },
//   // Japan
//   jp: {
//     newsletter: 'https://www.chaumet.com/jp_ja/newsletter-subscription',
//     contact: 'https://www.chaumet.com/jp_ja/contact'
//   },
//   ja: {
//     newsletter: 'https://www.chaumet.com/jp_ja/newsletter-subscription',
//     contact: 'https://www.chaumet.com/jp_ja/contact'
//   },
//   // Korea
//   kr: {
//     newsletter: 'https://www.chaumet.com/kr_kr/newsletter-subscription',
//     contact: 'https://www.chaumet.com/kr_kr/contact'
//   },
//   ko: {
//     newsletter: 'https://www.chaumet.com/kr_kr/newsletter-subscription',
//     contact: 'https://www.chaumet.com/kr_kr/contact'
//   },
//   // Singapore
//   sg: {
//     newsletter: 'https://www.chaumet.com/sg_cht/newsletter-subscription',
//     contact: 'https://www.chaumet.com/sg_cht/contact'
//   },
//   // Taiwan
//   tw: {
//     newsletter: 'https://www.chaumet.com/tw_cht/newsletter-subscription',
//     contact: 'https://www.chaumet.com/tw_cht/contact'
//   },
//   // China
//   cn: {
//     newsletter: 'https://www.chaumet.cn/cn_zh/contact',
//     contact: 'https://www.chaumet.cn/cn_zh/contact'
//   },
//   zh: {
//     newsletter: 'https://www.chaumet.cn/cn_zh/contact',
//     contact: 'https://www.chaumet.cn/cn_zh/contact'
//   },
//   // Middle East Countries (Kuwait, Qatar, Saudi Arabia)
//   kw: {
//     newsletter: 'https://www.chaumet.com/kw_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/kw_en/contact'
//   },
//   qa: {
//     newsletter: 'https://www.chaumet.com/qa_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/qa_en/contact'
//   },
//   sa: {
//     newsletter: 'https://www.chaumet.com/sa_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/sa_en/contact'
//   },
//   // Russia
//   ru: {
//     newsletter: 'https://www.chaumet.com/ru_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/ru_en/contact'
//   },
//   ar: {
//     newsletter: 'https://www.chaumet.com/ae_ar/newsletter-subscription',
//     contact: 'https://www.chaumet.com/ae_ar/contact'
//   },
//   // USA
//   us: {
//     newsletter: 'https://www.chaumet.com/us_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/us_en/contact'
//   },
//   // Canada
//   ca: {
//     newsletter: 'https://www.chaumet.com/ca_en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/ca_en/contact'
//   },
//   // Default URLs for remaining countries (Monaco, Macau SAR, Malaysia)
//   mc: {
//     newsletter: 'https://www.chaumet.com/en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/en/contact'
//   },
//   mo: {
//     newsletter: 'https://www.chaumet.com/en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/en/contact'
//   },
//   my: {
//     newsletter: 'https://www.chaumet.com/en/newsletter-subscription',
//     contact: 'https://www.chaumet.com/en/contact'
//   }
// };

export const urlMappings = {
  // France
  fr: {
    newsletter: 'https://www.chaumet.com/fr_fr/newsletter',
    contact: 'https://www.chaumet.com/fr_fr/contact'
  },
  // Spain
  es: {
    newsletter: 'https://www.chaumet.com/es_en/newsletter',
    contact: 'https://www.chaumet.com/es_en/contact'
  },
  // Great Britain
  gb: {
    newsletter: 'https://www.chaumet.com/gb_en/newsletter',
    contact: 'https://www.chaumet.com/gb_en/contact'
  },
  // Switzerland
  ch: {
    newsletter: 'https://www.chaumet.com/ch_fr/newsletter',
    contact: 'https://www.chaumet.com/ch_fr/contact'
  },
  // Russia
  ru: {
    newsletter: 'https://www.chaumet.com/ru_en/newsletter',
    contact: 'https://www.chaumet.com/ru_en/contact'
  },
  // EU Countries
  at: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  be: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  de: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  fi: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  ie: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  it: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  lu: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  nl: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  pt: {
    newsletter: 'https://www.chaumet.com/eu_en/newsletter',
    contact: 'https://www.chaumet.com/eu_en/contact'
  },
  // Nordic Countries
  dk: {
    newsletter: 'https://www.chaumet.com/dk_en/newsletter',
    contact: 'https://www.chaumet.com/dk_en/contact'
  },
  no: {
    newsletter: 'https://www.chaumet.com/no_en/newsletter',
    contact: 'https://www.chaumet.com/no_en/contact'
  },
  se: {
    newsletter: 'https://www.chaumet.com/se_en/newsletter',
    contact: 'https://www.chaumet.com/se_en/contact'
  },
  // APAC
  kr: {
    newsletter: 'https://www.chaumet.com/kr_kr/newsletter',
    contact: 'https://www.chaumet.com/kr_kr/contact'
  },
  ko: {
    newsletter: 'https://www.chaumet.com/kr_kr/newsletter',
    contact: 'https://www.chaumet.com/kr_kr/contact'
  },
  hk: {
    newsletter: 'https://www.chaumet.com/hk_cht/newsletter',
    contact: 'https://www.chaumet.com/hk_cht/contact'
  },
  tw: {
    newsletter: 'https://www.chaumet.com/tw_cht/newsletter',
    contact: 'https://www.chaumet.com/tw_cht/contact'
  },
  sg: {
    newsletter: 'https://www.chaumet.com/sg_cht/newsletter',
    contact: 'https://www.chaumet.com/sg_cht/contact'
  },
  au: {
    newsletter: 'https://www.chaumet.com/au_en/newsletter',
    contact: 'https://www.chaumet.com/au_en/contact'
  },
  nz: {
    newsletter: 'https://www.chaumet.com/nz_en/newsletter',
    contact: 'https://www.chaumet.com/nz_en/contact'
  },
  // Japan
  jp: {
    newsletter: 'https://www.chaumet.com/jp_ja/newsletter',
    contact: 'https://www.chaumet.com/jp_ja/contact'
  },
  ja: {
    newsletter: 'https://www.chaumet.com/jp_ja/newsletter',
    contact: 'https://www.chaumet.com/jp_ja/contact'
  },
  // Mainland China
  cn: {
    newsletter: 'https://www.chaumet.cn/cn_zh/contact',
    contact: 'https://www.chaumet.cn/cn_zh/contact'
  },
  zh: {
    newsletter: 'https://www.chaumet.cn/cn_zh/contact',
    contact: 'https://www.chaumet.cn/cn_zh/contact'
  },
  // US/CANADA
  us: {
    newsletter: 'https://www.chaumet.com/us_en/newsletter',
    contact: 'https://www.chaumet.com/us_en/contact'
  },
  ca: {
    newsletter: 'https://www.chaumet.com/ca_en/newsletter',
    contact: 'https://www.chaumet.com/ca_en/contact'
  },
  // Middle East
  ae: {
    newsletter: 'https://www.chaumet.com/ae_en/newsletter',
    contact: 'https://www.chaumet.com/ae_en/contact'
  },
  ar: {
    newsletter: 'https://www.chaumet.com/ae_ar/newsletter',
    contact: 'https://www.chaumet.com/ae_ar/contact'
  },
  kw: {
    newsletter: 'https://www.chaumet.com/kw_en/newsletter',
    contact: 'https://www.chaumet.com/kw_en/contact'
  },
  qa: {
    newsletter: 'https://www.chaumet.com/qa_en/newsletter',
    contact: 'https://www.chaumet.com/qa_en/contact'
  },
  sa: {
    newsletter: 'https://www.chaumet.com/sa_en/newsletter',
    contact: 'https://www.chaumet.com/sa_en/contact'
  },
  // Default URLs
  mc: {
    newsletter: 'https://www.chaumet.com/en/newsletter-subscription',
    contact: 'https://www.chaumet.com/en/contact'
  },
  mo: {
    newsletter: 'https://www.chaumet.com/en/newsletter-subscription',
    contact: 'https://www.chaumet.com/en/contact'
  },
  my: {
    newsletter: 'https://www.chaumet.com/en/newsletter-subscription',
    contact: 'https://www.chaumet.com/en/contact'
  },
  en: {
    newsletter: 'https://www.chaumet.com/gb_en/newsletter',
    contact: 'https://www.chaumet.com/gb_en/contact'
  }
};