import React, { useState, useCallback, useRef, useEffect } from 'react';
import styles from './GuestContactDetails.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { configActions } from '../store/config';
import Loading from './../components/Loading/Loading';
import Footer from '../components/Footer/Footer';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import AppointmentGuestResume from './../components/CreateAppointment/AppointmentGuestResume/AppointmentGuestResume';
import CreateClientForm from './../components/CreateClientForm/CreateClientForm';
import AppointmentCGIRGPD from './../components/CreateAppointment/AppointmentCGIRGPD/AppointmentCGIRGPD';
import ConfirmButton from '../UI/ConfirmButton/ConfirmButton';
import CancelButton from '../UI/CancelButton/CancelButton';
import ErrorMessage from '../UI/ErrorMessage/ErrorMessage';
import { createAppointment } from '../services/salesforce/index';
import useBackButtonHandler from './../hooks/useBackButtonHandler';
import { scrollToTop } from '../utils/Utils';
import { privacyPolicyUrls } from '../services/data';
import ReCaptcha from './../components/ReCaptcha/ReCaptcha';
import trackEvent from './../utils/trackEvent';
import { getPageName } from './../utils/Utils';
import ModalWebsiteInMaintenance from './../components/Modal/ModalWebsiteInMaintenance/ModalWebsiteInMaintenance';

const GuestContactDetails = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuth = useSelector((state) => state.config.isAuth);
    const isSessionExpired = useSelector((state) => state.config.isSessionExpired);
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    const privacyPolicyUrl = privacyPolicyUrls[clientLanguage] || privacyPolicyUrls.default;
    const [isLoading, setIsLoading] = useState(false);
    const appointmentParams = useSelector((state) => state.config.appointmentParams);
    const contactDetailsParams = useSelector((state) => state.config.contactDetailsParams);
    const createClientFormRef = useRef(null);
    const appointmentCGIRGPDRef = useRef(null);
    const captchaRef = useRef(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [errorMsg, setErrorMsg] = useState('There was an unexpected error with saving your appointment.');
    const [inputIsHidden, setInputIsHidden] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [errorMsgMaintenance, setErrorMsgMaintenance] = useState({ title: '', body: '' });
    const [isShowModalMaintenance, setIsShowModalMaintenance] = useState(false);
    const errorStatuses = ['errorTimeSlotAlreadyTake', 'errorFieldMissing', 'errorCaptcha', 'errorNameNotAllowed', 'errorStoreNotFound'];
    var displayCaptcha = true;
    if (!appointmentParams || !appointmentParams.comment) {
        displayCaptcha = true;
    } else {
        let commentTrimmed = appointmentParams.comment.trim();
        displayCaptcha = commentTrimmed !== 'bypass captcha';
    }

    const handleCTAClick = () => {
        trackEvent('cta_click', {
            ctaName: 'Confirm this appointment',
            pageName: getPageName(location.pathname),
            location_in_page: 'mid'
        });
    };

    const handleCTAError = errorName => {
        trackEvent('error', {
            errorName: errorName
        });
    };


    useEffect(() => {
        scrollToTop();
    }, [isLoading]);

    const handleCloseModalMaintenance = useCallback(() => {
        setIsShowModalMaintenance(false);
    }, []);

    const updateFormValues = useCallback((name, value) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    }, []);

    const createNewAppointment = useCallback(async (formValues) => {
        try {
            const response = await createAppointment(formValues);
            if (response.success === true) {
                if (response.data.data.status && response.data.data.status === "error") {
                    setInputIsHidden(false);
                    handleCTAError('error when trying to create an appointment');
                }
                else if (response.data.data.status && response.data.data.status === "insert failed") {
                    setErrorMsgMaintenance({ title: 'Error creating appointment', body: response.data.data.errorReason });
                    setIsShowModalMaintenance(true);
                    setErrorMsg(response.data.data.errorReason);
                    setInputIsHidden(false);
                    handleCTAError(response.data.data.errorReason);
                }
                else if (response.data.data.status && errorStatuses.includes(response.data.data.status)) {
                    setErrorMsg(response.data.data.errorReason);
                    setInputIsHidden(false);
                    handleCTAError(response.data.data.errorReason);
                }
                else {
                    trackEvent('form_completed', {
                        contactTheme: 'Create client account & create appointment'
                    });
                    setInputIsHidden(true);
                    dispatch(configActions.setAppointmentParams({}));
                    dispatch(configActions.setContactDetailsParams({}));
                    history.push({
                        pathname: '/appointmentResume',
                        state: formValues
                    });
                }
            }
            else {
                setInputIsHidden(true);
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
            setInputIsHidden(true);
            handleCTAError('unknow error when trying to create an appointment');
        }
    }, [dispatch, history, errorStatuses]);

    const removeBackButtonListener = useBackButtonHandler(() => editBtnClicked(false));

    //a commenter en prod
    // const handleConfirmButtonClicked = useCallback(async () => {
    //     setIsFormSubmitted(true);

    //     // Validation des formulaires client et CGI
    //     const { formValuesClient, isFormIsValidClient } = createClientFormRef.current.validateForm();
    //     const { formValuesCGI, isFormIsValidCGI } = appointmentCGIRGPDRef.current.validateForm();

    //     // Initialisation des variables de captcha
    //     let isFormIsValidCaptcha = !displayCaptcha; // true si pas besoin de captcha
    //     let token = '';

    //     // Vérification du captcha seulement si nécessaire
    //     if (displayCaptcha && captchaRef && captchaRef.current) {
    //         try {
    //             const captchaResult = await captchaRef.current.handleSubmit();
    //             isFormIsValidCaptcha = captchaResult.isFormIsValidCaptcha;
    //             token = captchaResult.token;
    //         } catch (error) {
    //             console.error('Erreur lors de la validation du captcha:', error);
    //             handleCTAError('Erreur lors de la validation du captcha:', error);
    //             isFormIsValidCaptcha = false;
    //         }
    //     }

    //     // Si tous les formulaires sont valides
    //     if (isFormIsValidClient && isFormIsValidCGI && isFormIsValidCaptcha) {
    //         setIsLoading(true);
    //         try {
    //             const allParams = {
    //                 ...formValuesClient,
    //                 ...formValuesCGI,
    //                 ...appointmentParams,
    //                 lng: clientLanguage,
    //                 captchaToken: displayCaptcha ? token : ''
    //             };

    //             // setEvent('BAA_cta_client_details_confirmation');
    //             handleCTAClick();
    //             await createNewAppointment(allParams);
    //         } catch (error) {
    //             console.error('Error when trying to create an appointment:', error);
    //             handleCTAError('Error when trying to create an appointment:', error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     }
    // }, [appointmentParams, createNewAppointment, clientLanguage, displayCaptcha]);


    //a remettre en prod
    const handleConfirmButtonClicked = useCallback(async () => {
        setIsFormSubmitted(true);
        const { formValuesClient, isFormIsValidClient } = createClientFormRef.current.validateForm();
        const { formValuesCGI, isFormIsValidCGI } = appointmentCGIRGPDRef.current.validateForm();
        const { isFormIsValidCaptcha, token } = await captchaRef.current.handleSubmit();
        if (isFormIsValidClient && isFormIsValidCGI && isFormIsValidCaptcha) {
            setIsLoading(true);
            const allParams = { ...formValuesClient, ...formValuesCGI, ...appointmentParams };
            allParams.lng = clientLanguage;
            allParams.captchaToken = token;
            createNewAppointment(allParams);
        }
    }, [appointmentParams, createNewAppointment, clientLanguage]);

    const handleCancelButtonClicked = useCallback(() => {
        dispatch(configActions.setAppointmentParams({}));
        dispatch(configActions.setContactDetailsParams({}));
        removeBackButtonListener();
        history.push({
            pathname: '/'
        });
    }, [dispatch, removeBackButtonListener, history]);

    const editBtnClicked = useCallback((isFromEditButton = true) => {
        const updatedContactDetails = { ...contactDetailsParams };
        Object.keys(formValues).forEach((key) => {
            if (formValues[key] !== contactDetailsParams[key]) {
                updatedContactDetails[key] = formValues[key];
            }
        });
        dispatch(configActions.setContactDetailsParams(updatedContactDetails));
        if (isFromEditButton) {
            history.goBack()
        }
    }, [formValues, history, contactDetailsParams, dispatch]);

    return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLanguage} />{isAuth ? <HeaderLogged clientLanguage={clientLanguage} /> : <HeaderGuest clientLanguage={clientLanguage} />}
            {!isSessionExpired ?
                isLoading ? <Loading /> : <div className={clientLanguage === "ar" ? styles.containerGuestRTL : styles.containerGuestLTR}>
                    <AppointmentGuestResume editBtnClicked={editBtnClicked} />
                    <CreateClientForm ref={createClientFormRef} clientLanguage={clientLanguage} isFormSubmitted={isFormSubmitted} updateFormValues={updateFormValues} />
                    <AppointmentCGIRGPD ref={appointmentCGIRGPDRef} />
                    <div className={styles.separator}></div>
                    <div className={styles.privacyContainer}>
                        <p className={clientLanguage === "ar" ? styles.privacyLabelRTL : styles.privacyLabelLTR}>
                            {t("I accept the") + ' '}
                            <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">{t("Privacy Policy")}</a>
                            {' ' + t("and certify that the information provided in this form is true and accurate.")}
                        </p>
                    </div>
                    <div className={styles.buttonBlockContainer}>
                        {displayCaptcha && <ReCaptcha ref={captchaRef} />}
                        {/* <ReCaptcha ref={captchaRef} /> */}
                        <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />
                        <ConfirmButton title={t("CONFIRM THIS APPOINTMENT")} handleButtonClicked={handleConfirmButtonClicked} />
                    </div>
                    <CancelButton title={t("CANCEL")} handleButtonClicked={handleCancelButtonClicked} />
                    <ModalWebsiteInMaintenance open={isShowModalMaintenance} onClose={handleCloseModalMaintenance} clientLanguage={clientLanguage} title={errorMsgMaintenance.title} body={errorMsgMaintenance.body} />
                </div>
                : <SessionExpired />}
        </div>
        <div>
            <Footer />
        </div>
    </>
};

export default GuestContactDetails;