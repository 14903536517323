
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './CancelAppointmentList.module.css';
import { getAppointmentList, cancelAppointment } from '../../services/salesforce/index';
import { filterList } from './../../utils/Utils';
import AppointmentList from './AppointmentList/AppointmentList';
import ModalSureToDelete from './../Modal/ModalSureToDelete/ModalSureToDelete';
import LoaderRequest from '../Loading/LoaderRequest/LoaderRequest';
import ModalCancelAppointmentDone from './../Modal/ModalCancelAppointmentDone/ModalCancelAppointmentDone';
import ConfirmButton from '../../UI/ConfirmButton/ConfirmButton';
import trackEvent from './../../utils/trackEvent';
import { getPageName } from './../../utils/Utils';

const CancelAppointmentList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { clientId } = useSelector((state) => state.config.clientInfos);
  const lng = useSelector((state) => state.config.lng);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingPopUp, setIsLoadingPopUp] = useState(false);
  const [isLoadingPopUpDone, setIsLoadingPopUpDone] = useState(false);
  const [nextAppointmentList, setNextAppointmentList] = useState([]);
  const [pastAppointmentList, setPastAppointmentList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({});

  useEffect(() => {
    refreshAppointmentList();
  }, []);

  const handleCTAClick = () => {
    trackEvent('cta_click', {
      ctaName: 'Create appointment',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
  };

  const refreshAppointmentList = useCallback(async () => {
    setIsLoadingList(true);
    const response = await getAppointmentList(clientId);
    if (response.data && response.data.length > 0) {
      const appointmentList = response.data;
      const pastAppointments = filterList(appointmentList, true);
      const nextAppointments = filterList(appointmentList, false);
      setPastAppointmentList(pastAppointments);
      setNextAppointmentList(nextAppointments);
    }
    setIsLoadingList(false);
  }, [clientId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseLoader = () => {
    setIsLoadingPopUp(false);
  };

  const handleCloseModalAppDone = () => {
    setIsLoadingPopUpDone(false);
  };

  const setCurrentAppointment = appointment => {
    setSelectedAppointment(appointment);
  };

  const createAppointment = useCallback(() => {
    handleCTAClick();
    history.push({
      pathname: '/'
    });
  }, [history]);

  const handleCTAClickCancel = () => {
    trackEvent('cta_click', {
      ctaName: 'Cancel appointment',
      pageName: getPageName(location.pathname),
      location_in_page: 'mid'
    });
  };

  const confirmCancelAppointment = useCallback(async () => {
    setOpen(false);
    setIsLoadingPopUp(true);
    handleCTAClickCancel();
    const data = { uuid: selectedAppointment.uuid };
    const response = await cancelAppointment(data);
    if (response.success === true) {
      setIsLoadingPopUpDone(true);
      const nextAppointmentListFiltered = [...nextAppointmentList].filter(apt => apt.id !== selectedAppointment.id);
      setNextAppointmentList(nextAppointmentListFiltered);
    }
    setIsLoadingPopUp(false);
  }, [nextAppointmentList, selectedAppointment]);

  return <div className={styles.container}>
    <AppointmentList pastAppointmentList={pastAppointmentList} nextAppointmentList={nextAppointmentList} handleClickOpen={handleClickOpen} setCurrentAppointment={setCurrentAppointment} refreshAppointmentList={refreshAppointmentList} clientLanguage={lng} isLoadingList={isLoadingList} />
    <ModalCancelAppointmentDone open={isLoadingPopUpDone} onClose={handleCloseModalAppDone} selectedAppointment={selectedAppointment} clientLanguage={lng} />
    <div className={styles.buttonContainer}>
      <ConfirmButton title={t("BOOK A NEW APPOINTMENT")} handleButtonClicked={createAppointment} />
    </div>
    <ModalSureToDelete open={open} onClose={handleClose} confirmCancelAppointment={confirmCancelAppointment} selectedAppointment={selectedAppointment} clientLanguage={lng} />
    <LoaderRequest open={isLoadingPopUp} onClose={handleCloseLoader} />
  </div>;
};

export default React.memo(CancelAppointmentList);
