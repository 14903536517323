/**
 * Function to push events to the dataLayer.
 * @param {string} eventName - The name of the event (header_click, footer_click, form_completed, etc.)
 * @param {object} eventData - Specific data for the event being tracked.
 */
const trackEvent = (eventName, eventData) => {
    window.dataLayer = window.dataLayer || []; // Ensure the dataLayer is initialized
    window.dataLayer.push({
        event: eventName, // Event name
        ...eventData, // Event-specific data
    });
};

export default trackEvent;
