import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
import 'moment/locale/es';
import 'moment/locale/ar';

const options = {
  'en_US': 'en',
  'fr': 'fr',
  'ja': 'ja',
  'zh_TW': 'zh-tw',
  'zh_CN': 'zh-cn',
  'ko': 'ko',
  'es': 'es',
  'ar': 'ar'
};

let defaultLanguage = 'en';

export const getDateFormat = (date, format, timezone) => {
  return moment.tz(date, timezone).format(format);
};

export const getDateFormatNoTZ = (date, format) => {
  return moment(date).format(format);
};

export const getDateFormatNoTZUTC = (date, format) => {
  return moment.utc(date).format(format);
};

export const setLocalDate = local => {
  defaultLanguage = options[local] || 'en_US';
  moment.locale(defaultLanguage);
};

export const getFomatDate = lang => {
  const format = lang === "fr" ? 'ddd DD.MM.YYYY' : 'ddd MM.DD.YYYY';
  return format;
};

export const getFomatDateShort = lang => {
  const format = lang === "fr" ? 'DD.MM.YYYY' : 'MM.DD.YYYY';
  return format;
};

export const getFomatDateShortTiret = lang => {
  const format = 'YYYY-MM-DD';
  return format;
};

export const getDateFromDateTime = date => {
  return moment(date).format('YYYY-MM-DD');
};

export const getFomatDateTime = lang => {
  const format = lang === "fr" ? 'DD.MM.YYYY - H:mm' : 'MM.DD.YYYY - hh:mm a';
  return format;
};

export const getFomatTime = lang => {
  const format = lang === "fr" ? 'H:mm' : 'hh:mm a';
  return format;
};

export const isSamedates = (date1, date2) => {
  const time = getDateFormatNoTZUTC(date1, 'DD.MM.YYYY - H:mm');
  const selectedTime = getDateFormatNoTZUTC(date2, 'DD.MM.YYYY - H:mm');
  return time === selectedTime;
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('key');
};

export const orderByDate = (list, isNext) => {
  const listSorted = list.sort((a, b) => {
    const dateA = moment(a.endDateTime);
    const dateB = moment(b.endDateTime);
    return isNext ? dateA.diff(dateB) : dateB.diff(dateA);
  });
  return listSorted;
}

const ENV = {
  localhost: 'https://chaumet--full.sandbox.my.site.com/appointment',
  dev: 'https://chaumet--dev.sandbox.my.site.com/appointment',
  full: 'https://chaumet--full.sandbox.my.site.com/appointment',
  prod: 'https://appointment.chaumet.com/',
}

export const getEnvUrl = () => {
  const baseUrl = window.location.hostname
  if (baseUrl.includes('localhost')) {
    return ENV.localhost
  } else if (baseUrl.includes('chaumet--dev')) {
    return ENV.dev
  } else if (baseUrl.includes('chaumet--full')) {
    return ENV.full
  } else {
    return ENV.prod
  }
}

export const getTranslationUrl = () => {
  const baseUrl = window.location.hostname
  if (baseUrl.includes('localhost')) {
    return ENV.localhost
  } else if (baseUrl.includes('chaumet--dev')) {
    return ENV.dev
  } else if (baseUrl.includes('chaumet--full')) {
    return ENV.full
  } else {
    return ENV.prod
  }
}

export const isProdEnvironment = () => {
  const environment = getEnvUrl();
  return environment === ENV.prod;
};

export const objectToStringFormat = (object, separator) => {
  var keys = Object.keys(object);
  return keys.join(`${separator} `);
};

export const filterList = (list, isPast = false) => {
  const today = moment();
  const filteredList = list.filter(item => {
    const schedEndTime = moment(item.endDateTime);
    return isPast ? schedEndTime.isBefore(today) : schedEndTime.isAfter(today);
  });
  return orderByDate(filteredList, !isPast);
};

export const getLastItemOfArray = list => {
  if (list.length > 0) {
    return list[list.length - 1];
  }
};

export const getLastItemOfObject = obj => {
  const keys = Object.keys(obj);
  return keys.length > 0 ? keys[keys.length - 1] : undefined;
};

export function getUniqueListOfObjectByDate(list) {
  const enabledDates = Object.keys(list || {})
    .flatMap((date) => list[date].map((appointment) => appointment.startTime.slice(0, 10)));
  return Array.from(new Set(enabledDates));
}

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const getFirstNCharacters = (inputString, n) => {
  return inputString.slice(0, n);
};

export const replaceCharacter = (character, replacedBy, string) => {
  return string.replace(character, replacedBy);
};

export const isMobile = () => {
  const { innerWidth: width } = window;
  return width < 1000;
};

export const getPageName = pathname => {
  switch (pathname) {
    case '/':
      return 'home';
    case '/addtocalendar':
      return 'add to calendar';
    case '/appointmentList':
      return 'appointment list';
    case '/contactDetails':
      return 'contact details';
    case '/appointmentResume':
      return 'appointment resume';
    case '/appointmentCancel':
      return 'appointment cancel';
    default:
      return 'other';
  }
};